// app/routes/login.tsx
import { FC } from "react";

import { json } from "@remix-run/node";
import type {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import { Form, useLoaderData } from "@remix-run/react";
import { Alert, Button, Input, Divider, Link } from "react-daisyui";
import { useTranslation } from "react-i18next";
import { FcGoogle } from "react-icons/fc";
import { SocialsProvider } from "remix-auth-socials";

import AthletifyUnionIcon from "~/assets/athletify-icon-union.svg";
import { authenticator } from "~/services/auth.server";
import { cookieCommit, cookieGet } from "~/services/session.server";

export const meta: MetaFunction = () => [{ title: "Athletify - Login" }];

interface SocialButtonProps {
  provider: SocialsProvider;
  icon: JSX.Element;
  label: string;
  token?: string;
}

export const SocialButton: FC<SocialButtonProps> = ({
  provider,
  icon,
  label,
  token,
}) => {
  return (
    <Form action={`/auth/${provider}`} method="post">
      <div className="w-full flex justify-center">
        <Button
          className="join-item flex-1 gap-2"
          name="token"
          variant="outline"
          value={token}
        >
          {icon}
          <span className="font-semibold">
            {label + " "}
            <span className="capitalize">{provider}</span>
          </span>
        </Button>
      </div>
    </Form>
  );
};

export const handle = { i18n: "login" };

export default function Screen() {
  const { t } = useTranslation("login");
  const { error } = useLoaderData() as Awaited<{ error: Error }>;

  return (
    <div className="flex flex-col w-full h-full items-center justify-center pt-8 gap-7">
      <div className="flex mx-5 xs:mx-0 md:w-[420px]">
        {error ? <Alert status="warning">{error.message}</Alert> : null}
      </div>
      <div className="flex mx-5 lg:m-0 md:w-[420px] flex-col p-10 items-center justify-center gap-2 font-sans  max-w-3xl rounded-md bg-white border border-border-light">
        <h1 className="text-xl">{t("sign-in")}</h1>
        <Form method="post" className="w-full flex flex-col items-center gap-4">
          <div className="form-control w-full">
            <label className="label" htmlFor="email">
              <span className="label-text">{t("email-address")}</span>
            </label>
            <Input
              id="email"
              type="email"
              name="email"
              placeholder={t("email-address")}
              autoComplete="email"
              required
            />
          </div>

          <div className="form-control w-full">
            <label className="label" htmlFor="password">
              <span className="label-text">{t("password")}</span>
            </label>
            <Input
              id="password"
              type="password"
              name="password"
              placeholder={t("password")}
              autoComplete="current-password"
              required
            />
          </div>
          <Link
            className="w-full text-xs px-1.5 underline"
            href="/forgot-password"
          >
            Forgot your password?
          </Link>
          <div className="form-control w-full">
            <Button className="btn-block" color="primary">
              {t("sign-in")}
            </Button>
          </div>
        </Form>

        <div className="w-full text-xs text-center">
          {t("dont-have")}{" "}
          <Link href="/create-account" className="underline">
            {t("create-account")}
          </Link>
        </div>

        <div className="w-full text-center">
          <Divider>{t("or")}</Divider>
        </div>

        <div className="form-control w-full">
          <SocialButton
            label={t("sign-in-with")}
            provider={SocialsProvider.GOOGLE}
            icon={<FcGoogle size={16} />}
          />
        </div>
      </div>
      <div className="text-xs text-center p-4 uppercase">
        <div className="text-gray-400 mb-2">Powered By</div>
        <div className="flex justify-center items-center">
          <img src={AthletifyUnionIcon} alt="logo" className="h-5" />
        </div>
      </div>
    </div>
  );
}

export async function action({ request }: ActionFunctionArgs) {
  const origin = new URL(request.url).searchParams.get("origin") ?? undefined;

  return await authenticator.authenticate("form", request, {
    successRedirect: `${origin ?? "/dashboard"}`,
    failureRedirect: `/login${origin ? `?origin=${origin}` : ""}`,
    context: { request },
  });
}

export async function loader({ request }: LoaderFunctionArgs) {
  const origin = new URL(request.url).searchParams.get("origin") ?? undefined;

  await authenticator.isAuthenticated(request, {
    successRedirect: `${origin ?? "/dashboard"}`,
  });
  const session = await cookieGet(request);
  const error = session.get(authenticator.sessionErrorKey);
  session.unset(authenticator.sessionErrorKey);

  return json(
    { error },
    {
      headers: {
        ...(await cookieCommit(session)),
      },
    },
  );
}
